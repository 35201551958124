import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import ConfigContext from "./utils/configContext"

export default class Gallery extends React.Component {
  static contextType = ConfigContext

  constructor(props) {
    super(props)
    this.state = {}
  }

  downloadApp = url => {
    const a = document.createElement("a")
    a.href = url
    a.download = url.split("/").pop()
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  nonChromeFunc = () => {
    this.props.toggleModal()
    setTimeout(() => {
      this.downloadApp(this.props.installUrl)
    }, 1100)
  }

  onClickHandler = eventSource => {
    this.props.fbEventAndShowModal(eventSource)
    this.props.isChrome ? this.props.togglePrompt() : this.nonChromeFunc()
    this.props.hideGallery()
  }

  componentDidMount() {
    this.slider.slickGoTo(this.props.slideToScroll)
  }

  render() {
    let { hideGallery, imageData, bannerArray } = this.props

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
    }
    const InstallButton = (
      <div className="column has-text-centered">
        <button
          className="button-install has-text-weight-medium"
          onClick={() => {
            this.onClickHandler("Gallery Button")
          }}
        >
          {this.context.installText || "INSTALL"}
        </button>
      </div>
    )

    const today = new Date().toJSON().slice(0, 10);

    if (!imageData) {
      return (
        <>
          <div className="custom-modal gallery-container">
            <div
              className="gallery-close"
              onClick={hideGallery}
              onKeyDown={hideGallery}
              role="button"
              tabIndex="0"
            >
              {" "}
              <span className=" is-large">
                <strong style={{ color: "#fff" }} className="mdi ">
                  X
                </strong>
              </span>
            </div>
            <div className="gallery-content">
              <div className={`gallery-slider ${this.context.gameCategory}`}>
                <Slider ref={slider => (this.slider = slider)} {...settings}>
                  {bannerArray.map((item, i) => (
                    <div key={i}>
                      <img
                        src={`https://img1.getmegacdn.com/landing-page-images/${item}?${today}`}
                        alt="getmega"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
              {InstallButton}
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="custom-modal gallery-container">
            <div
              className="gallery-close"
              onClick={hideGallery}
              onKeyDown={hideGallery}
              role="button"
              tabIndex="0"
            >
              <span className=" is-large">
                <strong style={{ color: "#fff" }} className="mdi ">
                  X
                </strong>
              </span>
            </div>
            <div className="gallery-content">
              <div className={`gallery-slider ${this.context.gameCategory}`}>
                <Slider ref={slider => (this.slider = slider)} {...settings}>
                  {imageData.map(({ image }, index) => {
                    return (
                      <div key={index}>
                        <img src={image} alt="slide" />
                      </div>
                    )
                  })}
                </Slider>
              </div>
              {InstallButton}
            </div>
          </div>
        </>
      )
    }
  }
}
